import { useNavigate } from "react-router-dom";

function MainPageBlog1Info() {
  const navigate = useNavigate();

  const moveToHome = () => {
    navigate("/");
  };

  const redirectToWhatsapp = () => {
    const whatsappLink = "https://wa.me/message/TAOIHODLNUQZC1";
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <div className="featured">
        <div className="component-container">
          <h2 className="featured-text-title">
            جلسه مشاوره به صورت حضوری و غیر حضوری با مشاور ارشد رسمی املاک دبی
            است
          </h2>
          <p className="feature-text">
            در این جلسه که به مدت ۶۰ دقیقه خواهد بود ، کلیه موارد مربوط به املاک
            و مستغلات ، سرمایه گذاری و راه اندازی تجارت و اخذ ویزای اقامت کشور
            امارات متحده عربی تحت پوشش خواهد بود.
          </p>
          <p className="feature-text">توجه داشته باشید:</p>
          <ol>
            <li className="feature-text">
              به سرمایه گذارانی که به مدت ۶۰ روز پس از انجام مشاوره اقدام به
              خرید ملک از طریق مشاور املاک مذکور را داشته باشند مبلغ ۱۰۰٪ حق
              مشاوره استرداد خواهد شد.
            </li>
            <li className="feature-text">
              ارسال مدارک شناسایی برای تایید وقت مشاوره الزامی است.
            </li>
            <li className="feature-text">
              تاریخ و ساعت جلسه ممکن است به دلیل حوادث غیرمترقبه تغییر کند. در
              این صورت، از طریق ایمیل وقت جدید اعلام خواهد شد.
            </li>
            <li className="feature-text">
              هزینه مشاوره حضوری و یا آنلاین به مدت ۶۰ دقیقه ۱۵۰۰ درهم میباشد.
            </li>
          </ol>
        </div>

        <div className="btnWrapper btnAppointment">
          <button className="btn btnBest" onClick={redirectToWhatsapp}>
            ارسال مدارک برای مشاوره
          </button>
          <button className="btn btnBest" onClick={moveToHome}>
            بازگشت به صفحه اصلی
          </button>
            
        </div>
      </div>
    </>
  );
}

export default MainPageBlog1Info;
