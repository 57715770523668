import { useNavigate } from "react-router-dom";
import "./MainPageInfo.css";

function MainPageBlog1Info() {
  const navigate = useNavigate();

  const moveToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="featured">
        <div className="component-container">
          <h2 className="featured-text-title">
            یکی از مهمترین سوالاتی که همواره با آن روبرو هستیم این است که مزایای
            خرید ملک در دبی چیست؟
          </h2>
          <p className="feature-text">
            دبی به علت جاذبه های توریستی و گردشگری ، همواره مورد توجه بسیاری از
            سرمایه گذاران بین المللی قرار گرفته است. میتوان گفت با تجربه 25 ساله
            زندگی در کشور امارات متحده عربی ، خود یکی از شاهدین این پیشرفت و
            توسعه میباشم.
          </p>
          <p className="feature-text">و اما مزایای خرید ملک در دبی :</p>
          <ol>
            <li className="feature-text">امنیت سرمایه</li>
            <li className="feature-text">
              ثابت بودن ارزش پول ملی  ( درهم اماراتی ) در مقابل دلار آمریکا
            </li>
            <li className="feature-text">
              اخذ اقامت خانوادگی ( نکته مهم : فقط به املاکی تعلق دارد که سند
              داشته باشند و کامل شده باشند . املاک پیش فروش شامل این دسته
              نخواهند بود)
            </li>
            <li className="feature-text">رشد اقتصادی پایدار</li>
            <li className="feature-text">معاف از مالیات بر درآمد</li>
            <li className="feature-text">
              زندگی مسالمت آمیز 200 ملیت در یک کشور
            </li>
            <li className="feature-text">
              روابط خارجه مسالمت آمیز با کلیه کشورهای جهان
            </li>
          </ol>
        </div>

        <div className="btnBestRatedWrapper">
          <button className="btn btnBestPage" onClick={moveToHome}>
            بازگشت به صفحه اصلی
          </button>
        </div>
      </div>
    </>
  );
}

export default MainPageBlog1Info;
