import { useState } from "react";
import "./Modal.css";
import { BsFillHouseFill } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Modal = ({ isModalOpen, handleModalSubmit, closeModal, name }) => {
  const [formData, setFormData] = useState({
    brochure_name: name,
    name: "",
    email: "",
    telephone: "",
  });

  const handleFormChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const form = event.target.form;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    handleModalSubmit(formData);
    closeModal();
  };

  return (
    <div className={isModalOpen ? "modal show" : "modal"}>
      <form noValidate className="modal-form">
        <div className="modal-close" onClick={closeModal}>
          <AiOutlineCloseCircle />
        </div>
        <h1>
          <span>
            <BsFillHouseFill />
            immobilien24
          </span>
          dubai
        </h1>
        <h3 className="modal-description">
          To start downloading the {name} brochure please enter your contact
          details
        </h3>
        <div className="brochure_name">
          <label>
            Brochure name:
            <input
              type="text"
              name="brochure_name"
              required
              value={formData.brochure_name}
              onChange={handleFormChange}
            />
          </label>
        </div>
        <label>
          Name*:
          <input
            type="text"
            name="name"
            autoComplete="name"
            required
            value={formData.name}
            onChange={handleFormChange}
          />
        </label>
        <label>
          Email*:
          <input
            type="email"
            name="email"
            autoComplete="email"
            required
            value={formData.email}
            onChange={handleFormChange}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          />
        </label>
        <label>
          Telephone(optional):
          <input
            type="tel"
            name="telephone"
            autoComplete="tel"
            required
            value={formData.telephone}
            onChange={handleFormChange}
          />
        </label>
        <button
          className="btn modal-btn"
          type="submit"
          onClick={handleFormSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Modal;
