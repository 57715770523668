import React, { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Navbar.css";
import { IconContext } from "react-icons";
import { BsFillHouseFill } from "react-icons/bs";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (sidebar && !e.target.closest(".menu-bars")) {
        setSidebar(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [sidebar]);

  return (
    <>
      <header>
        <IconContext.Provider value={{ color: "undefined" }}>
          <div className="navbar">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <h1>
              <span>
                <BsFillHouseFill className="house-icon" />
                ELHAM&nbsp;
              </span>
              Property
            </h1>
          </div>
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                if (item.isExternal) {
                  return (
                    <li key={index} className={item.cName}>
                      <a href={item.path} rel="noopener noreferrer">
                        {item.icon}
                        <span>{item.title}</span>
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </header>
    </>
  );
}

export default Navbar;
