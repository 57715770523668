import { useNavigate } from "react-router-dom";
import "./MainPageInfo.css";

function MainPageBlog2Info() {
  const navigate = useNavigate();

  const moveToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="featured">
        <div className="component-container">
          <h2 className="featured-text-title">
            :خرید ملک در دبی به دو دسته کلی تقسیم میشود
          </h2>
          <p className="featured-text-bold">اول - املاک آماده دارای سند رسمی</p>
          <p className="feature-text">
            اگر چنانچه قصد خرید ملک آماده را در دبی دارید ، باید حتما با یک
            مشاور رسمی که دارای جواز از اداره املاک دبی است تماس بگیرید و یا از
            طریق آنلاین توسط وبسایتهای رسمی ملک مورد نظر را جستجو کنید هزینه
            هایی که در بر خواهید داشت به این ترتیب خواهند بود:
          </p>
          <ol>
            <li className="feature-text">ارزش ملک طبق توافق با فروشنده</li>
            <li className="feature-text">
              هزینه انتقال سند به اداره دولتی املاک دبی یعنی ۴% از ارزش ملک که
              در قرارداد نوشته شده است.
            </li>
            <li className="feature-text">
              کمیسیون مشاور املاک یعنی ۲% از ارزش ملک که در قرارداد نوشته شده
              است.
            </li>
            <li className="feature-text">
              هزینه محضری ثبت سند حدودا هزار دلار است ( ممکن است بستگی به نوع
              ملک متغیر باشد)
            </li>
            <li className="feature-text">
              چنانچه ملک تجاری باشد باید ۵% مالیات از ارزش ملک که در قرارداد
              نوشته شده است ، پرداخت شود.
            </li>
          </ol>

          <p className="featured-text-bold">
            دوم - املاک پیش ساخته بدون سند رسمی
          </p>
          <p className="feature-text">
            چنانچه به دنبال خرید املاک پیش ساخته در دبی هستید ، فقط از شرکتهایی
            خرید کنید که سابقه درخشان داشته و پروژه های متعددی را ساخته و تحویل
            داده اند. هزینه های که در بر خواهید داشت به این ترتیب خواهند بود:
          </p>
          <ol>
            <li className="feature-text">
              ارزش ملک که معمولا به صورت اقساط است و باید در قرارداد رسمی از
              شرکت سازنده درج شده باشد.
            </li>
            <li className="feature-text">
              کمیسیون از طرف خریدار در املاک پیش ساخته صفر است.
            </li>
            <li className="feature-text">
              هزینه انتقال سند به اداره دولتی املاک دبی مبلغ ۴% از ارزش ملک است
              که بعضا ۲% توسط شرکت سازنده پرداخت میشود و ۲% توسط خریدار.
            </li>
            <li className="feature-text">
              چنانچه ملک تجاری باشد باید ۵% از ارزش ملک به عنوان مالیات پرداخت
              شود.
            </li>
            <li className="feature-text">
              هزینه دفتری ثبت قرارداد حدودا هزار دلار است. ( ممکن است بستگی به
              نوع ملک متغیر باشد).
            </li>
          </ol>
        </div>
        <div className="hint-text">
          لازم به ذکر است که هزینه های فوق ممکن است توسط اداره دولتی املاک متغیر
          باشد ، بنابراین قبل از اقدام به خرید از کلیه این هزینه ها توسط مشاور
          رسمی فروش ، مطلع باشید
        </div>
        <div className="btnBestRatedWrapper">
          <button className="btn btnBestPage" onClick={moveToHome}>
            بازگشت به صفحه اصلی
          </button>
        </div>
      </div>
    </>
  );
}

export default MainPageBlog2Info;
