import Best from "../../components/best/Best";
import Hero from "../../components/hero/Hero";

function Home() {
  return (
    <>
      <Hero />
      <Best />
    </>
  );
}

export default Home;
