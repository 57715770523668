import { useNavigate } from "react-router-dom";
import benefits from "../../assets/mazaya.png";
import expences from "../../assets/hazineha.jpeg";
import ownership from "../../assets/j.webp";

import "./Best.css";

const Best = () => {
  const navigate = useNavigate();
  const moveToMainPageBlog1Info = () => {
    navigate("/home-blog-1");
  };
  const moveToMainPageBlog2Info = () => {
    navigate("/home-blog-2");
  };
  const moveToMainPageBlog3Info = () => {
    navigate("/home-blog-3");
  };

  return (
    <div className="best">
      <h2>مزایای خرید ملک در دبی</h2>
      <div className="bestRatedImgSliderContainer">
        {/* <ImageSlider slides={slides} /> */}
        <img src={benefits} alt="bg-dubai" />
      </div>
      <button className="btn btnBest" onClick={moveToMainPageBlog1Info}>
        بیشتر بدانید
      </button>
      <h2>هزینه های خـــرید ملک در دبی</h2>
      <div className="bestRatedImgSliderContainer">
        {/* <ImageSlider slides={slides} /> */}
        <img src={expences} alt="bg-dubai1" />
      </div>
      <button className="btn btnBest" onClick={moveToMainPageBlog2Info}>
        بیشتر بدانید
      </button>
      <h2>مالکیت ملک در دبی</h2>
      <div className="bestRatedImgSliderContainer">
        {/* <ImageSlider slides={slides} /> */}
        <img src={ownership} alt="bg-dubai2" />
      </div>
      <button className="btn btnBest" onClick={moveToMainPageBlog3Info}>
        بیشتر بدانید
      </button>
    </div>
  );
};

export default Best;
