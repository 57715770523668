import React, { useState, useContext, createContext } from "react";

const GalleryContext = createContext();
const GalleryUpdateContext = createContext();

export function useGallery() {
  return useContext(GalleryContext);
}

export function useGalleryUpdate() {
  return useContext(GalleryUpdateContext);
}

export function GalleryProvider({ children }) {
  const [openModal, setOpenModal] = useState(false);

  function openGallery() {
    setOpenModal((galleryOpen) => !galleryOpen);
  }

  return (
    <GalleryContext.Provider value={openModal}>
      <GalleryUpdateContext.Provider value={openGallery}>
        {children}
      </GalleryUpdateContext.Provider>
    </GalleryContext.Provider>
  );
}
