import { downloadFiles } from "../../utils/data/downloads/downloads";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from "../../components/modal/Modal";
import "./Downloads.css";
import pdfIcon from "../../assets/bg-Dubai.jpg"; // Fallback PDF icon

function Downloads() {
  const [fileIndex, setModalFileIndex] = useState(null);

  const handleDownloadClick = (index) => {
    setModalFileIndex(index);
  };

  const closeModal = () => {
    setModalFileIndex(null);
  };

  const handleModalSubmit = (formData, pdfUrl, filename, index) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_DOWNLOAD,
        formData,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("Email sent successfully:", response);
        },
        function (error) {
          console.log("Email sending failed:", error);
        }
      );

    downloadPDF(pdfUrl, filename);
  };

  function downloadPDF(pdfUrl, filename) {
    if (!filename) {
      console.error("Property file name is not defined");
      return;
    }
    const fileName = `${filename}.pdf`;
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div className="downloads-container">
      {downloadFiles.map((file, index) => (
        <div key={file.id} className="pdf-file">
          <div className="pdf-preview">
            <img src={file.file || pdfIcon} alt={""} />
          </div>
          <div className="pdf-info">
            <div className="pdf-name">{file.fileName}</div>
            <button className="btn" onClick={() => handleDownloadClick(index)}>
              Download Brochure
            </button>

            <Modal
              isModalOpen={fileIndex === index}
              handleModalSubmit={(formData) =>
                handleModalSubmit(formData, file.file, file.fileName, index)
              }
              closeModal={closeModal}
              name={file.fileName}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Downloads;
