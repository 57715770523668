import React from "react";

import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer-distributed">
        <div className="footer-left">
          <h3>
            ELHAM&nbsp;<span>Property</span>
          </h3>

          <p className="footer-links">
            <a href="/">صفحه اصلی</a>|<a href="/aboutus">معرفی</a>|
            <a href="/contact">تماس با من</a>
          </p>

          <p className="footer-company-name">
            Copyright © 2023 <strong>elham property</strong> All rights reserved
          </p>
        </div>

        <div className="footer-center">
          <div>
            <i className="fa fa-map-marker"></i>
            <p>
              <span>Dubai</span>
              UAE
            </p>
          </div>

          <div>
            <i className="fa fa-phone"></i>
            <p>+971 55 4289975</p>
          </div>
          <div>
            <i className="fa fa-envelope"></i>
            <p>
              <a href="mailto:elham@providentestate.com">
                elham@providentestate.com
              </a>
            </p>
          </div>
        </div>
        <div className="footer-right">
          <p className="footer-company-about">
            <span>خدمات </span>
            سرمایه گذاری و خرید ملک ، اخذ اقامت طلایی ، ثبت شرکت و راه اندازی
            تجارت
          </p>
          <div className="footer-icons">
            <a
              href="https://www.facebook.com/ElhamMolaeiForuhar?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="icon" />
            </a>
            <a
              href="https://www.instagram.com/propertyconsultant.dubai/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="icon" />
            </a>

            <a
              href="https://www.youtube.com/@elhammolaeiforouhar5923"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube className="icon" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
