import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();
  const moveToConsultancy = () => {
    navigate("/consultancy");
  };
  return (
    <div className="hero-container">
      <div className="hero-main-image"></div>
      <div className="content-container">
        <h1>مشورت قبل از تصمیم گیری کلید موفقیت است</h1>

        <button className="hero-button btn" onClick={moveToConsultancy}>
          تعیین وقت مشاوره
        </button>
      </div>
    </div>
  );
};

export default Hero;
