import React from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiContactsBookFill from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa";

export const SidebarData = [
  {
    title: "صفحه اصلی",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
    isExternal: false,
  },
  {
    title: "معرفی",
    path: "/aboutus",
    icon: <IoIcons.IoIosPaper />,
    cName: "nav-text",
    isExternal: false,
  },
  {
    title: "دانلود",
    path: "/downloads",
    icon: <FaFilePdf />,
    cName: "nav-text",
    isExternal: false,
  },
  {
    title: "تماس با من",
    path: "/contact",
    icon: <RiContactsBookFill.RiAccountBoxFill />,
    cName: "nav-text",
    isExternal: false,
  },
];
