import pdf1 from "../../../assets/pdf-brochures/general-infos/Armani_Residences_Brochure.pdf";
import pdf2 from "../../../assets/pdf-brochures/general-infos/Burj_Khalifa_Brochure.pdf";
import pdf3 from "../../../assets/pdf-brochures/general-infos/Catch_Residences_Digital_Brochure.pdf";
import pdf4 from "../../../assets/pdf-brochures/general-infos/CP_Digital_Brochure_ENGLISH-v2.pdf";
import pdf5 from "../../../assets/pdf-brochures/general-infos/DUBAI_CREEK_HARBOUR__MASTERPLAN_MAP.pdf";
import pdf6 from "../../../assets/pdf-brochures/general-infos/dubai-creek-harbour-dubai-creek-harbour-emaar-master-plan-002.pdf";
import pdf7 from "../../../assets/pdf-brochures/general-infos/EMAAR_DUBAI_MAP.pdf";
import pdf8 from "../../../assets/pdf-brochures/general-infos/Final-MJL_Lamtara_BROCHURE_2021.pdf";
import pdf9 from "../../../assets/pdf-brochures/general-infos/JUNE_2_ARABIAN_RANCHES_III_MASTERPLAN.pdf";
import pdf10 from "../../../assets/pdf-brochures/general-infos/LAGOONS_Brochure.pdf";
import pdf11 from "../../../assets/pdf-brochures/general-infos/Mansio_Floor_plans_brochure_with_floor_plates.pdf";
import pdf12 from "../../../assets/pdf-brochures/general-infos/Master Plan_R3_Elie_Saab.pdf";
import pdf13 from "../../../assets/pdf-brochures/general-infos/Master Plan_R3_Elie_Saab_(1).pdf";
import pdf14 from "../../../assets/pdf-brochures/general-infos/Master_Plan_with_Combined_Plot_Number.pdf";
import pdf15 from "../../../assets/pdf-brochures/general-infos/MJL_ASAYEL_BROCH_Apr-2021.pdf";
import pdf16 from "../../../assets/pdf-brochures/general-infos/MJL_ASAYEL_BROCH_Apr-2021_(1).pdf";
import pdf17 from "../../../assets/pdf-brochures/general-infos/PALACE_RESIDENCDES_CREEK_BLUE_DCH_FLOOR_PLANS.pdf";
import pdf18 from "../../../assets/pdf-brochures/general-infos/PALACE_RESIDENCDES_CREEK_BLUE_DCH_FLOOR_PLANS_(1).pdf";
import pdf19 from "../../../assets/pdf-brochures/general-infos/Peninsula_Two_Brochure.pdf";
import pdf20 from "../../../assets/pdf-brochures/general-infos/The_Crest_Opp_Doc_Digital.pdf";
import pdf21 from "../../../assets/pdf-brochures/general-infos/ADH_RAK_AL_MARJAN_ISLAND_BROCHURE-1.pdf";
import pdf22 from "../../../assets/pdf-brochures/general-infos/BAYVIEW_BROCHURE_EN.pdf";
import pdf23 from "../../../assets/pdf-brochures/general-infos/BAYVIEW_EBF_MASTERPLAN_MAP.jpg";
import pdf24 from "../../../assets/pdf-brochures/general-infos/DH2 VIRDIS BROCHURE DB En.pdf";
import pdf25 from "../../../assets/pdf-brochures/general-infos/Damac DTP Brochure.pdf";
import pdf26 from "../../../assets/pdf-brochures/general-infos/MAJESTINE_Brochure.pdf";
import pdf27 from "../../../assets/pdf-brochures/general-infos/MERANO_Brochure.pdf";
import pdf28 from "../../../assets/pdf-brochures/general-infos/PRIVE_Brochure.pdf";
import pdf29 from "../../../assets/pdf-brochures/general-infos/PTHR_BROCHURE_H2 (3).pdf";
import pdf30 from "../../../assets/pdf-brochures/general-infos/PTHR_BROCHURE_R2.pdf";
import pdf31 from "../../../assets/pdf-brochures/general-infos/REVA_BRO.pdf";
import pdf32 from "../../../assets/pdf-brochures/general-infos/VERA_Brochure.pdf";
import pdf33 from "../../../assets/pdf-brochures/general-infos/ZADA_Brochure.pdf";

export const downloadFiles = [
  {
    id: "01",
    fileName: "Armani Residences",
    file: pdf1,
  },
  {
    id: "02",
    fileName: "Burj Khalifa",
    file: pdf2,
  },
  {
    id: "03",
    fileName: "Catch Residences Digital",
    file: pdf3,
  },
  {
    id: "04",
    fileName: "CP_Digital",
    file: pdf4,
  },
  {
    id: "05",
    fileName: "DUBAI CREEK HARBOUR MASTERPLAN MAP",
    file: pdf5,
  },
  {
    id: "06",
    fileName: "Dubai Creek Harbour Emaar Master Plan",
    file: pdf6,
  },
  {
    id: "07",
    fileName: "EMAAR DUBAI MAP",
    file: pdf7,
  },
  {
    id: "08",
    fileName: "Final MJL Lamtara",
    file: pdf8,
  },
  {
    id: "09",
    fileName: "JUNE 2 ARABIAN RANCHES III MASTERPLAN",
    file: pdf9,
  },
  {
    id: "10",
    fileName: "LAGOONS",
    file: pdf10,
  },
  {
    id: "11",
    fileName: "Mansio Floor Plans With Floor Plates",
    file: pdf11,
  },
  {
    id: "12",
    fileName: "Master Plan R3 Elie Saab",
    file: pdf12,
  },
  {
    id: "13",
    fileName: "Master Plan R3 Elie Saab (1)",
    file: pdf13,
  },
  {
    id: "14",
    fileName: "Master Plan With Combined Plot Number",
    file: pdf14,
  },
  {
    id: "15",
    fileName: "MJL ASAYEL BROCH Apr-2021",
    file: pdf15,
  },
  {
    id: "16",
    fileName: "MJL ASAYEL BROCH Apr-2021 (1)",
    file: pdf16,
  },
  {
    id: "17",
    fileName: "PALACE RESIDENCDES CREEK BLUE DCH FLOOR PLANS",
    file: pdf17,
  },
  {
    id: "18",
    fileName: "PALACE RESIDENCDES CREEK BLUE DCH FLOOR PLANS(1)",
    file: pdf18,
  },
  {
    id: "19",
    fileName: "Peninsula Two Brochure",
    file: pdf19,
  },
  {
    id: "20",
    fileName: "The Crest Opp Doc Digital",
    file: pdf20,
  },
  {
    id: "21",
    fileName: "ADH RAK AL MARJAN ISLAND",
    file: pdf21,
  },
  {
    id: "22",
    fileName: "BAYVIEW",
    file: pdf22,
  },
  {
    id: "23",
    fileName: "BAYVIEW EBF MASTERPLAN MAP",
    file: pdf23,
  },
  {
    id: "24",
    fileName: "DH2 VIRDIS DB",
    file: pdf24,
  },
  {
    id: "25",
    fileName: "DAMAC DTP",
    file: pdf25,
  },
  {
    id: "26",
    fileName: "MAJESTINE",
    file: pdf26,
  },
  {
    id: "27",
    fileName: "MERANO",
    file: pdf27,
  },
  {
    id: "28",
    fileName: "PRIVE",
    file: pdf28,
  },
  {
    id: "29",
    fileName: "PTHR H2",
    file: pdf29,
  },
  {
    id: "30",
    fileName: "PTHR R2",
    file: pdf30,
  },
  {
    id: "31",
    fileName: "REV BRO",
    file: pdf31,
  },
  {
    id: "32",
    fileName: "VERA",
    file: pdf32,
  },
  {
    id: "33",
    fileName: "ZADA",
    file: pdf33,
  },
];
