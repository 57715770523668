import "./NotFind.css";
const NotFound = () => (
  <div className="error-container">
    <div className="error-wrapper">
      <h1>404 Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  </div>
);

export default NotFound;
