import { useNavigate } from "react-router-dom";
import "./Documents.css";
import beCard from "../../assets/bro-card.png";
import certificate from "../../assets/certificate.png";

function Documents() {
  const navigate = useNavigate();
  const moveToHome = () => {
    navigate("/");
  };
  return (
    <>
      <div className="section">
        <div className="document-title">
          <h1>مدارک</h1>
        </div>
        <h3 className="document-text">
          کارت صادر شده از اداره املاک و اراضی دبی
        </h3>
        <div className="bestRatedImgSliderContainer doc-pic">
          <img src={beCard} alt="bc-card" />
        </div>

        <h3 className="document-text">گواهی تخصصی شرکت امار</h3>
        <div className="bestRatedImgSliderContainer doc-pic">
          <img src={certificate} alt="certificate" />
        </div>
      </div>
      <div className="btnWrapper btnWrapperDocs">
        <button className="btn btnBest" onClick={moveToHome}>
          بازگشت به صفحه اصلی
        </button>
      </div>
    </>
  );
}

export default Documents;
