import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact.jsx";
import Document from "./Pages/Documents/Documents.jsx";
import Downloads from "./Pages/Downloads/Downloads.jsx";
import { GalleryProvider } from "./GalleryContext";
import MainPageBlog1Info from "./Pages/MainPageInfo/MainPageBlog1Info.jsx";
import MainPageBlog2Info from "./Pages/MainPageInfo/MainPageBlog2Info.jsx";
import MainPageBlog3Info from "./Pages/MainPageInfo/MainPageBlog3Info.jsx";
import Appointment from "./Pages/Appointment/Appointment.jsx";
import NotFound from "../src/utils/errors/NotFind.jsx";

// layouts
import RootLayout from "./layouts/RootLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/documents" element={<Document />} />
      <Route path="/home-blog-1" element={<MainPageBlog1Info />} />
      <Route path="/home-blog-2" element={<MainPageBlog2Info />} />
      <Route path="/home-blog-3" element={<MainPageBlog3Info />} />
      <Route path="/consultancy" element={<Appointment />} />
      <Route path="*" element={<NotFound />} />
      {/* <Route
        path="/ready-to-move-propertie/:id"
        element={<ReadyToMovePropertieListed />}
      />
      <Route
        path="/under-construction-properties-listing/:id"
        element={<UnderConstructionPropertiesListing />}
      /> */}
    </Route>
  )
);

function App() {
  return (
    <>
      <main>
        <GalleryProvider>
          <RouterProvider router={router} />
        </GalleryProvider>
      </main>
    </>
  );
}

export default App;
