import { Outlet } from "react-router-dom";
import Navbar from "../components/sideBarNav/Navbar.jsx";
import Footer from "../components/footer/Footer";
import ScrollToTop from "../utils/ScrollToTop.js";

export default function RootLayout() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <main>
        <Outlet />
        <Footer />
      </main>
    </>
  );
}
