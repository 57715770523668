import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./About.css";
import Chefin from "../../images/elhamForouhar.png";

const ReadMore = ({ children, maxCharacterCount = 250 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(false);
  const resultString = isReadMore
    ? text.toString().slice(0, maxCharacterCount)
    : true;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {resultString}
      <div className="button" onClick={toggleReadMore}>
        <NavLink>{!isReadMore ? "بیشتر بخوانید" : "نمایش کمتر"}</NavLink>
      </div>
    </p>
  );
};

function About() {
  const navigate = useNavigate();
  const moveToHome = () => {
    navigate("/");
  };
  const moveToDocuments = () => {
    navigate("/documents");
  };
  return (
    <>
      <div className="section">
        <div className="container-about">
          <div className="content-section">
            <div className="title">{/* <h1>معرفی</h1> */}</div>
            <div className="content">
              <h3>
                <span className="first-child">
                  {" "}
                   مهندس الهام مولائى فروهر مشاور ارشد امور ملکی و سرمایه گذاری
                  در شهر زیبای دبی
                </span>
                <br />
                <br />
                <div className="second-child">
                  فروشنده املاک منحصر به فرد در شهرهای ابوظبی و راس الخیمه
                </div>
                <br />
                <br />
                خانم مهندس الهام مولائى فروهر در سال ۲۰۰۱ میلادی به کشور امارات
                متحده عربی مهاجرت کرد و فعاليت حرفه ای خود را در زمینه خرید و
                فروش املاک و مستغلات دبی از سال ۲۰۱۴ میلادی در شهر زیبای دبی
                آغاز نمود و هم اکنون به عنوان نماینده برتر شرکتهای سازنده معتبر
                در دبی شناخته شده است. ایشان یک متخصص با درک عمیق از چشم انداز
                املاک دبی است. بینش و راهنمایی های او به سرمایه گذاران بسیاری
                کمک کرده است تا تصمیمات آگاهانه بگیرند و به بازده قابل توجهی در
                این بازار پویا دست یابند.
              </h3>
              <div className="btnWrapper">
                <button className="btn btnBest" onClick={moveToDocuments}>
                  مدارک
                </button>
                <button className="btn btnBest" onClick={moveToHome}>
                  بازگشت به صفحه اصلی
                </button>
              </div>
            </div>
          </div>
          <div className="image-section">
            <img src={Chefin} alt="about-us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
