import { useNavigate } from "react-router-dom";
import "./MainPageInfo.css";

function MainPageBlog3Info() {
  const navigate = useNavigate();

  const moveToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="featured">
        <div className="component-container">
          <h2 className="featured-text-title">
            در سال 2002 میلادی فرمانی توسط اعلی حضرت شیخ محمد بن راشد آل مکتوم
            حاکم دبی و نخست وزیر کشور امارات متحده عربی مبنی بر قانون مالکیت
            مادام العمر صادر شد و در نواحی مشخص شده سرمایه گذاران میتوانند مالک
            مادام العمر باشند
          </h2>
          <p className="feature-text">
            هم اکنون مالکیت املاک و مستغلات در شهر دبی دو نوع است .
          </p>
          <h3 className="feature-text">اول - مالکیت مادام العمر</h3>
          <p className="feature-text">
            در این نوع املاک ، سرمایه گذار و خریدار مالک مطلق است که مشخصات
            ایشان توسط اداره املاک در سند به عنوان مالک قید میشود و در صورت فوت
            سرمایه گذار این ملک به ورثه خواهد رسید. میتوان از منطقه بیزینس بی ،
            داون تاون ، دبی هیلز استیت و ....به عنوان مثال نام برد.
          </p>
          <h3 className="feature-text">دوم - مالکیت محدود</h3>
          <p className="feature-text">
            <p className="feature-text">
              در این نوع املاک ، سرمایه گذار به مدت محدودی مالک ملک است. این مدت
              محدود 85 ساله و یا 99 ساله است. املاکی که مالکیت محدود دارند با
              شرایط خاصی که باید به تایید اداره دولتی املاک دبی و مشارکت دادگاه
              دبی است ، به ورثه منقل خواهد شد. اما در همه موارد یکسان نیست.
            </p>
          </p>
        </div>
        <div className="hint-text">
          با این وجود اگر به دنبال خرید ملک در دبی هستید ، بهتر است در رابطه با
          نوع مالکیت مطمئن شوید و گزارش کاملی از معاملات اخیر در آن منطقه را از
          مشاور املاک بخواهید
        </div>
        <div className="btnBestRatedWrapper">
          <button className="btn btnBestPage" onClick={moveToHome}>
            بازگشت به صفحه اصلی
          </button>
        </div>
      </div>
    </>
  );
}

export default MainPageBlog3Info;
